<template>
  <div class="billingcreate">
    <!-- 单据表单 -->
    <div class="billingcreate_form">
      <ul class="form_ul">
        <li v-for="item in data">
          <div class="form_title">
            <span class="mustHint">*</span>
            {{item.name}}:
          </div>
          <!-- 输入框 -->
          <div class="form_date" v-if="item.type==0">
            <input type="text" v-model="data[item.filename]" placeholder="选择...">
          </div>
          <!-- 下拉框 -->
          <div class="form_date" v-if="item.type==1">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>
              <i class="el-icon-more"></i>
            </span>
          </div>
          <!-- 弹框 -->
          <div class="form_date" v-if="item.type==2">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span>
              <i class="el-icon-more"></i>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'qqq',
    data() {
      return {
        data: [
          {
            name: '客户',
            type:0,
            filename:'aaa',
          },
          {
            name: '单据编号',
            type:0,
            filename:'bbb',
          }
        ]
      }
    },
    watch:{
      data:{
        handler(newVal,oldVal){
          console.log(newVal);
        },
        deep:true
      }
    },
    created() {
      let str = "客户名称,单据编号,报价类型,单据日期,出货仓库,交货日期,发票类型,付款方式,物流方式,关联单据号,结算方式,报价有效期,业务员,部门,合同号,预计交货日期,收款说明,优惠前无税总金额,优惠前折扣总金额,优惠前总税额,预估总毛利,预估总毛利率,无税总金额,订单来源,1688订单状态,1688订单退款状态,1688交易类型,1688订单业务类型,收货人信息,物流信息,发票信息,付款信息";
      let arr = str.split(",");
      let arrData = []
      let arrStr = "";
      console.log(arr);
      arr.forEach(item=>{
        // console.log(item);
        if(!item in arrData){
          arrData.push(item);
          arrStr = arrStr + "," + item;
          console.log(arrStr);
        }
      })
      console.log(arrStr);
    }
  }
</script>

<style lang="less" src="@/less/Comm/comm_billing.less"></style>
